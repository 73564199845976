import { getItem } from "@/utils/storage";
import { RCP_SITE_CONFIG } from '@/store/module/index'
import * as math from 'mathjs';

export const formatTime = (time) => {
  console.log(time);
  if (time < 60) {
    return `${time}秒`
  } else if (60 <= time && time < 3600) {
    return `${Math.floor(time / 60)}分`
  } else {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor(Math.floor(time % 3600) / 60);
    const h = hours.toString().length === 1 ? `0${hours}` : hours;
    const m = minutes.toString().length === 1 ? `0${minutes}` : minutes;
    return `${h}时${m}分`
  }
}
export const secondformatTime = (time) => {
  console.log(time);
  if (time < 60) {
    return `${time}秒`
  } else if (60 <= time && time < 3600) {
    return `${Math.floor(time / 60)}分${time % 60}秒`
  } else {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor(Math.floor(time % 3600) / 60);
    const seconds = Math.floor(Math.floor(time % 3600) % 60);
    const h = hours.toString().length === 1 ? `0${hours}` : hours;
    const m = minutes.toString().length === 1 ? `0${minutes}` : minutes;
    const s = seconds.toString().length === 1 ? `0${seconds}` : seconds;
    return `${h}时${m}分${s}秒`
  }
}
export const verdictApi = (res, func) => {
  if (res.data && typeof func === 'function') {
    if (res.data.code === 0) {
      func()
    }
  } else {
    throw new Error('传入参数res错误');
  }
}

export const nullification = (option) => {
  const form = {}
  Object.keys(option).forEach((item) => {
    if (option[item] || option[item] === 0) {
      form[item] = option[item]
    }
  })
  return form
}
// 防抖
export function XcDebounce(options, interval = 500) {
  let timer;
  if (typeof options === "function" && typeof interval === "number") {
    return function (...value) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        options(...value)
      }, interval)
    }
  } else {
    throw '实参类型错误! 示例: (function,number)'
  }
}


// 节流
export function XcThrottle(options, interval = 500) {
  let flag = true
  if (typeof options === "function" && typeof interval === "number") {
    return function (...value) {
      if (!flag) return
      flag = false
      options(...value)
      setTimeout(() => {
        flag = true
      }, interval)
    }
  } else {
    throw '实参类型错误! 示例: (function,number)';
  }
}

// 映射
export function XcMapData(contentSource, optionSource, contentDictionaries, optionDictionaries) {
  if (contentSource?.constructor === Object && contentDictionaries?.constructor === Object) {
    if (typeof optionSource === 'string' && typeof optionDictionaries === 'string') {
      contentSource[optionSource] = contentDictionaries[optionDictionaries]
    }
    else if (Array.isArray(optionSource) && Array.isArray(optionDictionaries)) {
      optionSource.forEach((item, index) => {
        contentSource[item] = contentDictionaries[optionDictionaries[index]]
      })
    } else { throw '源选项或字典选项类型错误!' }
  }
  else if (Array.isArray(contentSource) && contentDictionaries?.constructor === Object) {
    if (contentSource.every(item => item?.constructor === Object)) {
      if (typeof optionSource === 'string' && typeof optionDictionaries === 'string') {
        contentSource.forEach((item) => {
          item[optionSource] = contentDictionaries[optionDictionaries]
        })
      }
      else if (Array.isArray(optionSource) && Array.isArray(optionDictionaries)) {
        contentSource.forEach((item) => {
          optionSource.forEach((items, index) => {
            item[items] = contentDictionaries[optionDictionaries[index]]
          })
        })
      } else { throw '源选项或字典选项类型错误!' }
    }
  }
  else if (Array.isArray(contentSource) && Array.isArray(contentDictionaries)) {
    if (contentSource.every(item => item?.constructor === Object) && contentDictionaries.every(item => item?.constructor === Object)) {
      if (Array.isArray(optionSource) && Array.isArray(optionDictionaries)) {
        contentSource.forEach((item) => {
          contentDictionaries.forEach((items) => {
            if (item[optionSource[0]] === items[optionDictionaries[0]]) {
              optionSource.forEach((itemss, index) => {
                if (index > 0) {
                  item[itemss] = items[optionDictionaries[index]]
                }
              })
            }
          })
        })
      }
      else if (optionSource?.constructor === Object && optionDictionaries?.constructor === Object) {
        contentSource.forEach((item) => {
          contentDictionaries.forEach((items) => {
            if (item[optionSource.check] === items[optionDictionaries.check]) {
              Object.keys(optionSource).forEach((itemss) => {
                if (itemss !== 'check') {
                  item[optionSource[itemss]] = items[optionDictionaries[itemss]]
                }
              })
            }
          })
        })
      }
    } else { throw '源选项或字典选项类型错误!' }
  }
  else { throw '源数据或字典类型错误!' }
  return contentSource
}


function bankerRound(number, size) {
  // abs代表结果的正（1）负（-1）
  let abs = 1
  if (number < 0) abs = -1
  // 为便于计算，将number转为整数，最后返回时再加符号
  number = Math.abs(number)
  // numArr为分割小数点的数组，即整数部分和小数部分
  const numArr = number.toString().split('.')
  const factor = Math.pow(10, size)

  // 如果有小数部分，并且小数部分的长度大于要保留的位数
  if (numArr.length === 2 && numArr[1].length > size) {
    // 下面是判断保留位数的后一位是否为5，
    // 5后非零就进一，5后为零看奇偶，5前为偶应舍去，5前为奇要进一
    if (numArr[1].substring(size, size + 1) === '5') {
      // sizeChar为5前一位，如1.8845保留3位，则sizeChar为4
      let sizeChar = numArr[1].substring(size - 1, size);
      // 如果size为0，则需要取个位
      if (size === 0) {
        sizeChar = numArr[0].slice(-1);
      }
      if (!checkIsZero(numArr[1], size)) {
        // 5后非零就进一
        return ((Math.round(number * factor) / factor) * abs).toFixed(size)
      } else if (Number(sizeChar) % 2 === 0) {
        // 5前为偶应舍去
        return ((Math.trunc(number * factor) * abs) / factor).toString()
      } else {
        // 5前为奇要进一
        return (((Math.trunc(number * factor) + 1) * abs) / factor).toString()
      }
    } else {
      // 不为5，直接按照四舍五入进位
      return ((Math.round(number * factor) / factor) * abs).toFixed(size)
    }
  } else {
    // 如果没有小数部分，或者小数部分的长度小于等于要保留的位数，则只需要补0就行了
    return (number * abs).toFixed(size)
  }
}

function checkIsZero(numStr, size) {
  for (let i = size + 1; i < numStr.length; i++) {
    if (numStr[i] !== '0') {
      return false
    }
  }
  return true
}

//进一，保留两位小数，保留整数
export function NumberFormat(num, isBalance = false) {
  var result = num;
  const type = getItem(RCP_SITE_CONFIG).pay_round_type || 0 //("pay_round_type");
  const accuracy = getItem(RCP_SITE_CONFIG).pay_round_accuracy || 0 // uni.getStorageSync("pay_round_accuracy") || 0
  if (isBalance && type === 0) {
    return Number(num);
  }
  if (accuracy === 1) {
    result = refinedCalculationFun(num, 10, 'multiply')
    // num *= 10;
  }
  if (type === 2) {
    result = Math.ceil(result);
  } else if (type === 1) {
    result = Math.floor(result);
  } else if (type === 3) {
    return result = bankerRound(num, accuracy);
  }
  if (accuracy == 1) {
    result = refinedCalculationFun(result, 10, 'divide')
    // result /= 10;
  }
  return result;
}

export const timeFormat = (option, type = 'common') => {
  if (option) {
    if (type === 'short') {
      return option.substring(0, 10).split('-').join('')
    } else {
      return option.substring(0, 10)
    }
  } else {
    return ''
  }

}

export const rold = [
  '全部', '总经办', '区总', '', '店长', '主管', '助教管理员', '教练', '收银员', '助教组长', '助教', '服务员', '租户管理员', '平台管理员', '大区区总', '总经理', '门店投资人', '领班', '保洁'
]

export const refinedCalculationFun = (num1, num2, type) => {
  if (type === 'add') { // 加
    return math.add(math.bignumber(num1), math.bignumber(num2))
  } else if (type === 'multiply') { // 乘
    return math.multiply(math.bignumber(num1), math.bignumber(num2))
  } else if (type === 'subtract') { // 减
    return math.subtract(math.bignumber(num1), math.bignumber(num2))
  } else if (type === 'divide') { // 除
    return math.divide(math.bignumber(num1), math.bignumber(num2))
  } else {
    return math.add(math.bignumber(num1), math.bignumber(num2))
  }
}
