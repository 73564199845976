export const RCP_URL_HOST = 'RCP_URL_HOST'
export const RCP_TOKEN = 'RCP_TOKEN'
export const RCP_REFRESH_TOKEN = 'RCP_REFRESH_TOKEN'
export const RCP_NICK_NAME = 'RCP_NICK_NAME'
export const RCP_AVATAR = 'RCP_AVATAR'
export const RCP_TENANT_PROFILE = 'RCP_TENANT_PROFILE'
export const RCP_SITE_PROFILE = 'RCP_SITE_PROFILE'
export const RCP_SITE_CONFIG = 'RCP_SITE_CONFIG'
export const RCP_TENANT_CONFIG = 'RCP_TENANT_CONFIG'
export const RCP_SHOW_TYPE = 'RCP_SHOW_TYPE'
export const RCP_ROLE_LIST = 'RCP_ROLE_LIST'
export const RCP_ROLE_ID = 'RCP_ROLE_ID'