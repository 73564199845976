<script setup>
import { computed } from "vue";
import { XcDebounce, XcThrottle } from '@/utils/index'

const props = defineProps(['show', 'content', 'footer'])
const emits = defineEmits(['close', 'cancel', 'confirm'])
const show = computed(() => props.show)
const cancel = () => { emits('cancel') }
const confirm = () => { emits('confirm') }
const selectXcDebounce = XcThrottle(confirm, 2000)

</script>

<template>
  <van-popup v-model:show="show" :close-on-click-overlay="false">
    <div class="dialog-global">
      <div class="dialog-title">提示</div>
      <div class="dialog-content" v-if="props.content">{{ props.content }}</div>
      <slot name="content"></slot>
      <div class="zhanwei"></div>
      <div class="dialog-footer" v-if="props.footer !== false">
        <div class="cancel" @click="cancel">取消</div>
        <div class="confirm" @click="selectXcDebounce">确定</div>
      </div>
      <slot name="footer"></slot>
    </div>
  </van-popup>
</template>

<style lang="less" scoped>
.dialog-global {
  width: 312px;
  background-color: #eee;
  border-radius: 15px;
  padding: 18px 25px;
  user-select: none;

  .dialog-title {
    font-size: 14px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-bottom: 8px;
  }

  .dialog-content {
    text-align: center;
    font-size: 12.5px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #333333;
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;

    .cancel,
    .confirm {
      cursor: pointer;
      width: 88px;
      height: 30px;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
    }

    .cancel {
      color: #666666;
      background: #FFFFFF;
    }

    .confirm {
      color: #fff;
      background: #00A29A;
    }
  }

  .zhanwei {
    height: 10px;
  }
}
</style>