import { getItem, setItem, removeItem } from "@/utils/storage";
import {
  RCP_TOKEN,
  RCP_REFRESH_TOKEN,
  RCP_NICK_NAME,
  RCP_AVATAR,
  RCP_TENANT_PROFILE,
  RCP_SITE_PROFILE,
  RCP_SITE_CONFIG,
  RCP_TENANT_CONFIG,
  RCP_URL_HOST,
  RCP_SHOW_TYPE,
  RCP_ROLE_LIST,
  RCP_ROLE_ID
} from './index'
import router from '@/router'
import store from '../index'

const users = {
  namespaced: true,
  state: {
    showType: getItem(RCP_SHOW_TYPE) === false ? false : true,
    token: getItem(RCP_TOKEN) || '',
    refreshToken: getItem(RCP_REFRESH_TOKEN) || '',
    phone: '',
    nickname: getItem(RCP_NICK_NAME) || '',
    roleId: getItem(RCP_ROLE_ID) || 0,
    username: '',
    avatar: getItem(RCP_AVATAR) || '',
    tenantProfile: getItem(RCP_TENANT_PROFILE) || {},
    siteProfile: getItem(RCP_SITE_PROFILE) || {},
    siteConfig: getItem(RCP_SITE_CONFIG) || {},
    tenantConfig: getItem(RCP_TENANT_CONFIG) || {},
    roleList: getItem(RCP_ROLE_LIST) || [],
    assistantLevels: [],
    creditCount: 0,
    pointName: '',
    cashierAreaIdList: [],
    memberCardList: [],
  },
  mutations: {
    setRelationWithCashier(state, payloud) {
      state.pointName = payloud.pointName
      state.cashierAreaIdList = payloud.tenantAreaIdList
    },
    getAssistantLevels(state, payloud) {
      state.assistantLevels = payloud
    },
    saveRoleList(state, payloud) {
      state.roleList = payloud
      setItem(RCP_ROLE_LIST, payloud)
    },
    getCreditCount(state, payloud) {
      state.creditCount = payloud
    },
    changeShowType(state, payloud) {
      state.showType = payloud
      setItem(RCP_SHOW_TYPE, payloud)
    },
    setToken(state, payloud) {
      state.token = payloud.accessToken
      state.refreshToken = payloud.refreshToken
      setItem(RCP_TOKEN, payloud.accessToken)
      setItem(RCP_REFRESH_TOKEN, payloud.refreshToken)
    },
    setUserInfo(state, payloud) {
      state.nickname = payloud.siteList[0].aliasName
      setItem(RCP_NICK_NAME, payloud.siteList[0].aliasName)
      state.roleId = payloud.roleId
      setItem(RCP_ROLE_ID, payloud.roleId)
      state.avatar = 'https://oss.ficoo.vip/maUiImages/images/defaultAvatar.png'
      setItem(RCP_AVATAR, 'https://oss.ficoo.vip/maUiImages/images/defaultAvatar.png')
    },
    setTenantProfile(state, payloud) {
      state.tenantProfile = payloud
      setItem(RCP_TENANT_PROFILE, payloud)
    },
    setTenantConfig(state, payloud) {
      state.tenantConfig = payloud
      setItem(RCP_TENANT_CONFIG, payloud)
    },
    setSiteProfile(state, payloud) {
      state.siteProfile = payloud
      setItem(RCP_SITE_PROFILE, payloud)
    },
    setSiteConfig(state, payloud) {
      state.siteConfig = payloud
      setItem(RCP_SITE_CONFIG, payloud)
    },
    removeUserInfo(state) {
      store.commit('settle/resetOrder')
      store.commit('charge/resetOrder')
      store.commit('table/resetOrder')
      store.commit('takeOut/resetOrder')
      localStorage.clear()
      let origin = window.location.origin + window.location.pathname
      setItem(RCP_URL_HOST, origin)
      state.showType = true
      state.token = ''
      state.phone = ''
      state.nickname = ''
      state.username = ''
      state.avatar = ''
      state.tenantProfile = {}
      state.siteProfile = {}
      state.siteConfig = {}

      router.replace({ path: '/login' });
      location.reload()
    },
    getMemberList(state, payloud) {
      state.memberCardList = payloud
    }
  },
  actions: {

  }
}
export default users
