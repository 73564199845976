const integration = {
  namespaced: true,
  state: {
    vip: {
      nickname: '',
      mobile: '',
      password: '',
      balance: '',
      memberId: '',
      point: ''
    },
    goodsList: [],
    assistantList: [],
    totalMoney: 0,
    money: 0,
  },
  mutations: {
    bindMember(state, payloud) {
      state.vip.mobile = payloud.mobile
      state.vip.password = payloud.password
      state.vip.balance = payloud.balance
      state.vip.memberId = payloud.id
      state.vip.nickname = payloud.nickname
      state.vip.point = payloud.point
    },
    removeMember(state) {
      state.vip = {
        nickname: '',
        mobile: '',
        password: '',
        balance: '',
        memberId: ''
      }
    },
    resetOrder(state) {
      state.vip = {
        nickname: '',
        mobile: '',
        password: '',
        balance: '',
        memberId: ''
      }
      state.goodsList = []
      state.assistantList = []
      state.totalMoney = 0
      state.money = 0
    },
    addGoods(state, payloud) {
      state.goodsList.push({ ...payloud, discount: 10, number: 1, salesUserId: 0 })
    },
    removeGoods(state, payloud) {
      state.goodsList = state.goodsList.filter((item) => item.id !== payloud.id)
    },
    bindGoodsCoach(state, payloud) {
      console.log(state.goodsList, payloud);
      state.goodsList = state.goodsList.map((item) => {
        if (item.id === payloud.option.id) {
          item.salesUserId = payloud.coach.selectRoleId
        }
        return item
      })
      console.log(state.goodsList);
    },
    goodsDiscount(state, payloud) {
      state.goodsList = state.goodsList.map((item) => {
        if (item.id === payloud.option.id) {
          if (payloud.discount.coach.selectRoleId) {
            item.salesUserId = payloud.discount.coach.selectRoleId
          }
          item.discount = Number(payloud.discount.discount)
        }
        return item
      })
      console.log(state.goodsList);
    },
    addReward(state, payloud) {
      state.assistantList.push(payloud)
    },
    removeReward(state, payloud) {
      state.assistantList = state.assistantList.filter((item) => item.id !== payloud.id)
    },
  }
}
export default integration